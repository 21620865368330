import LoadingScreen from 'components/LoadingScreen';
import React from 'react';
import { createRoot } from 'react-dom/client';
import loadable from '@loadable/component';
import './index.scss';

const container = document.getElementById('root');

if (container) {
  const AppWrapper = loadable(() => import('./AppWrapper'), {
    fallback: <LoadingScreen />,
  });

  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  );
}
