import { PaletteMode } from '@mui/material';

const COLOR_MODE_KEY = 'parking_driver_cabinet_color_mode';

export const getColorMode = (): PaletteMode | null => {
  const mode = localStorage.getItem(COLOR_MODE_KEY);
  if (mode === null || mode === 'dark' || mode === 'light') {
    return mode;
  }
  return null;
};

export const setColorMode = (mode: PaletteMode | null) => {
  if (mode) {
    localStorage.setItem(COLOR_MODE_KEY, mode);
  } else {
    localStorage.removeItem(COLOR_MODE_KEY);
  }
};
