import Logo from 'components/Logo';
import { getColorMode } from 'components/utils/colorMode';
import styles from './styles.module.scss';

const LoadingScreen = () => {
  const colorMode = getColorMode();
  const darkMode = colorMode === 'dark';
  const lightMode = colorMode === 'light';

  return (
    <div
      className={`${styles.root} ${darkMode ? styles.dark : ''} ${
        lightMode ? styles.light : ''
      }`}
    >
      <Logo variant={darkMode ? 'dark' : 'light'} />
    </div>
  );
};

export default LoadingScreen;
