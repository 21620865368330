import React from 'react';
import logo from 'images/logo.svg';
import logoDark from 'images/logo-dark.svg';
import styles from './styles.module.scss';

export type LogoPros = React.HTMLProps<HTMLDivElement> & {
  variant?: 'dark' | 'light';
};

const Logo = ({ variant = 'light', ...props }: LogoPros) => {
  const dark = variant === 'dark';
  return (
    <div {...props}>
      <div className={styles.logoRoot}>
        <img src={dark ? logoDark : logo} alt="" className={styles.logoImage} />
        <div className={`${styles.logoText} ${dark ? styles.dark : ''}`}>
          Система
          <br />
          городского
          <br />
          паркинга
        </div>
      </div>
    </div>
  );
};

export default Logo;
